<!--
 * @Descripttion:
 * @version:
 * @Author: 张天鹏
 * @Date: 2019-12-11 16:07:31
 * @LastEditors: 刘格优
 * @LastEditTime: 2021-01-28 18:19:37
 -->

<template>
  <div class="mainbody">
    <div class="messagelist">
      <div class="message">
        <div
          :style="{ '--color': getStorage('theme') }"
          class="message-top wbtop"
        >
          <div class="leftbox">
            <span class="redbg"></span>
            <p>被保人信息</p>
            <div class="up_icon">
              <span
                :style="{ color: getStorage('theme') }"
                @click="showPop"
                class="set"
                >修改</span
              >
              <md-icon
                :style="{ color: getStorage('theme') }"
                class="icon_right"
                name="arrow-right"
              ></md-icon>
            </div>
          </div>
          
          <md-popup v-model="isPopupShow2" position="bottom">
            <md-popup-title-bar
              title="修改被保人信息"
              ok-text=""
              cancel-text=""
              @confirm="hidePopUp"
              @cancel="hidePopUp"
            ></md-popup-title-bar>
            <div class="update_info">
              <div class="name_sex_age">
                <md-input-item
                  ref="input9"
                  title="姓名"
                  placeholder="请输入姓名"
                  v-model="from.customer.pidname"
                ></md-input-item>
                <span
                  ><img src="../../../../assets/jhs/img/input_name.png" alt=""
                /></span>
                <md-field-item title="性别" align="right">
                  <md-radio-group v-model="from.customer.apidsex2">
                    <md-radio-box
                      :style="{
                        borderColor:
                          from.customer.apidsex2 == '男'
                            ? getStorage('theme')
                            : '#6B6B6B',
                        color:
                          from.customer.apidsex2 == '男'
                            ? getStorage('theme')
                            : '#6B6B6B',
                      }"
                      name="男"
                      >男</md-radio-box
                    >
                    <md-radio-box
                      :style="{
                        borderColor:
                          from.customer.apidsex2 == '女'
                            ? getStorage('theme')
                            : '#6B6B6B',
                        color:
                          from.customer.apidsex2 == '女'
                            ? getStorage('theme')
                            : '#6B6B6B',
                      }"
                      name="女"
                      >女</md-radio-box
                    >
                  </md-radio-group>
                </md-field-item>
                <md-field-item name="name" title="年龄">
                  <input
                    readonly
                    class="bir"
                    v-model="datePickerValue4"
                    @click="isDatePickerShow4 = true"
                    placeholder="生日(选填)"
                    type="text"
                  />
                  <div class="icon">
                    <md-icon name="rectangle"></md-icon>
                  </div>
                  <input
                    readonly
                    :value="`${myReason3}岁`"
                    @click="ageee1 = true"
                    class="Age"
                    type="text"
                  />
                  <div class="icon icon1">
                    <md-icon name="rectangle"></md-icon>
                  </div>
                </md-field-item>
                <md-selector
                  v-model="ageee1"
                  default-value="2"
                  :data="reasons"
                  max-height="320px"
                  title="请选择年龄"
                  large-radius
                  @choose="onSelectorChoose4"
                ></md-selector>
                <md-date-picker
                  ref="datePicker3"
                  :min-date="aminDate1"
                  :max-date="maxDate1"
                  v-model="isDatePickerShow4"
                  type="custom"
                  title="选择日期"
                  large-radius
                  :text-render="textRender"
                  :custom-types="['yyyy', 'MM', 'dd']"
                  :default-date="currentDate"
                  @change="onDatePickerChange3"
                  @confirm="onDatePickerConfirm3"
                ></md-date-picker>
              </div>
            </div>
            <div class="keep_button">
              <md-button
                :style="{ backgroundColor: getStorage('theme') }"
                @click="baocun_bei"
                type="primary"
                >保存</md-button
              >
            </div>
          </md-popup>
        </div>
        <div class="message-bom">
          <md-field>
            <md-field-item title="性别" align="right">
              <md-radio-group v-model="from.customer.pidsex">
                <md-radio-box
                  :style="{
                    borderColor:
                      from.customer.pidsex == '男'
                        ? getStorage('theme')
                        : '#6B6B6B',
                    color:
                      from.customer.pidsex == '男'
                        ? getStorage('theme')
                        : '#6B6B6B',
                  }"
                  name="男"
                  >男</md-radio-box
                >
                <md-radio-box
                  :style="{
                    borderColor:
                      from.customer.pidsex == '女'
                        ? getStorage('theme')
                        : '#6B6B6B',
                    color:
                      from.customer.pidsex == '女'
                        ? getStorage('theme')
                        : '#6B6B6B',
                  }"
                  name="女"
                  >女</md-radio-box
                >
              </md-radio-group>
            </md-field-item>
            <md-field-item name="name" title="年龄">
              <div class="ag">
                <input
                  readonly
                  class="bir"
                  v-model="datePickerValue1"
                  @click="isDatePickerShow1 = true"
                  placeholder="生日(选填)"
                  type="text"
                />
                <div class="icon"><md-icon name="rectangle"></md-icon></div>
                <input
                  readonly
                  :value="`${from.customer.pidage}岁`"
                  @click="agee1 = true"
                  class="Age"
                  type="text"
                />
                <div class="icon icon1">
                  <md-icon name="rectangle"></md-icon>
                </div>
              </div>
            </md-field-item>
            <md-selector
              v-model="agee1"
              default-value="2"
              :data="reasons"
              max-height="320px"
              title="请选择年龄"
              large-radius
              @choose="onSelectorChoose2"
            ></md-selector>

            <md-date-picker
              ref="datePicker1"
              :min-date="aminDate1"
              :max-date="maxDate1"
              v-model="isDatePickerShow1"
              type="custom"
              title="选择日期"
              large-radius
              :text-render="textRender"
              :custom-types="['yyyy', 'MM', 'dd']"
              :default-date="currentDate"
              @change="onDatePickerChange1"
              @confirm="onDatePickerConfirm1"
            ></md-date-picker>
          </md-field>
        </div>
        <div
          :style="{ '--color': getStorage('theme') }"
          class="message-top wbtop"
        >
          <div class="leftbox">
            <span class="redbg"></span>
            <p>投保人信息</p>
            <div v-if="addform.is == '否'" @click="showPopUp" class="up_icon">
              <span :style="{ color: getStorage('theme') }" class="set"
                >修改</span
              >
              <md-icon
                :style="{ color: getStorage('theme') }"
                class="icon_right"
                name="arrow-right"
              ></md-icon>
            </div>
          </div>
          <md-popup v-model="isPopupShow1" position="bottom">
            <md-popup-title-bar
              title="修改投保人信息"
              ok-text=""
              cancel-text=""
              @confirm="hidePopUp"
              @cancel="hidePopUp"
            ></md-popup-title-bar>
            <div class="update_info">
              <div class="name_sex_age">
                <md-input-item
                  ref="input9"
                  title="姓名"
                  placeholder="请输入姓名"
                  v-model="from.customer.apidname"
                ></md-input-item>
                <span
                  ><img src="../../../../assets/jhs/img/input_name.png" alt=""
                /></span>
                <md-field-item title="性别" align="right">
                  <md-radio-group v-model="from.customer.apidsex1">
                    <md-radio-box
                      :style="{
                        borderColor:
                          from.customer.apidsex1 == '男'
                            ? getStorage('theme')
                            : '#6B6B6B',
                        color:
                          from.customer.apidsex1 == '男'
                            ? getStorage('theme')
                            : '#6B6B6B',
                      }"
                      name="男"
                      >男</md-radio-box
                    >
                    <md-radio-box
                      :style="{
                        borderColor:
                          from.customer.apidsex1 == '女'
                            ? getStorage('theme')
                            : '#6B6B6B',
                        color:
                          from.customer.apidsex1 == '女'
                            ? getStorage('theme')
                            : '#6B6B6B',
                      }"
                      name="女"
                      >女</md-radio-box
                    >
                  </md-radio-group>
                </md-field-item>
                <md-field-item name="name" title="年龄">
                  <div style="display: flex; justify-content: flex-end">
                    <input
                      readonly
                      class="bir"
                      v-model="datePickerValue3"
                      @click="isDatePickerShow3 = true"
                      placeholder="生日(选填)"
                      type="text"
                    />
                    <div class="icon">
                      <md-icon name="rectangle"></md-icon>
                    </div>
                    <input
                      readonly
                      :value="`${myReason2}岁`"
                      :content="selectorValue"
                      @click="ageee = true"
                      class="Age"
                      type="text"
                    />
                    <div class="icon icon1">
                      <md-icon name="rectangle"></md-icon>
                    </div>
                  </div>
                </md-field-item>
                <md-selector
                  v-model="ageee"
                  default-value="2"
                  :data="reasons1"
                  max-height="320px"
                  title="请选择年龄"
                  large-radius
                  @choose="onSelectorChoose3"
                ></md-selector>
                <md-date-picker
                  ref="datePicker2"
                  :min-date="aminDate1"
                  :max-date="amaxDate1"
                  v-model="isDatePickerShow3"
                  type="custom"
                  title="选择日期"
                  large-radius
                  :text-render="textRender"
                  :custom-types="['yyyy', 'MM', 'dd']"
                  :default-date="currentDate"
                  @change="onDatePickerChange2"
                  @confirm="onDatePickerConfirm2"
                ></md-date-picker>
              </div>
            </div>
            <div class="keep_button">
              <md-button
                :style="{ backgroundColor: getStorage('theme') }"
                @click="baocun_tou"
                type="primary"
                >保 存</md-button
              >
            </div>
          </md-popup>
        </div>
        <div class="message-bom">
          <div>
            <md-field v-if="is_showxn">
              <md-field-item title="投被保人是同一人" align="right">
                <md-radio-group v-model="addform.is">
                  <div @click="noshow_xn" class="display">
                    <md-radio-box
                      :style="{
                        borderColor:
                          addform.is == '否' ? getStorage('theme') : '#6B6B6B',
                        color:
                          addform.is == '否' ? getStorage('theme') : '#6B6B6B',
                      }"
                      name="否"
                      >否</md-radio-box
                    >
                  </div>
                  <div @click="isshow_xn" class="display">
                    <md-radio-box
                      :style="{
                        borderColor:
                          addform.is == '是' ? getStorage('theme') : '#6B6B6B',
                        color:
                          addform.is == '是' ? getStorage('theme') : '#6B6B6B',
                      }"
                      name="是"
                      >是</md-radio-box
                    >
                  </div>
                </md-radio-group>
              </md-field-item>
              <md-field-item
                v-if="addform.is == '否'"
                title="性别"
                align="right"
              >
                <md-radio-group v-model="from.customer.apidsex">
                  <md-radio-box
                    :style="{
                      borderColor:
                        from.customer.apidsex == '男'
                          ? getStorage('theme')
                          : '#6B6B6B',
                      color:
                        from.customer.apidsex == '男'
                          ? getStorage('theme')
                          : '#6B6B6B',
                    }"
                    name="男"
                    >男</md-radio-box
                  >
                  <md-radio-box
                    :style="{
                      borderColor:
                        from.customer.apidsex == '女'
                          ? getStorage('theme')
                          : '#6B6B6B',
                      color:
                        from.customer.apidsex == '女'
                          ? getStorage('theme')
                          : '#6B6B6B',
                    }"
                    name="女"
                    >女</md-radio-box
                  >
                </md-radio-group>
              </md-field-item>
              <md-field-item v-if="addform.is == '否'" name="name" title="年龄">
                <div class="ag">
                  <input
                    readonly
                    class="bir"
                    v-model="datePickerValue2"
                    @click="isDatePickerShow2 = true"
                    placeholder="生日(选填)"
                    type="text"
                  />
                  <div class="icon"><md-icon name="rectangle"></md-icon></div>
                  <input
                    readonly
                    :value="`${from.customer.apidage}岁`"
                    @click="agee = true"
                    class="Age"
                    type="text"
                  />
                  <div class="icon icon1">
                    <md-icon name="rectangle"></md-icon>
                  </div>
                </div>
              </md-field-item>
              <md-selector
                v-model="agee"
                default-value="2"
                :data="reasons1"
                max-height="320px"
                title="请选择年龄"
                large-radius
                @choose="onSelectorChoose1"
              ></md-selector>
              <md-date-picker
                ref="datePicker"
                :min-date="aminDate1"
                :max-date="amaxDate1"
                v-model="isDatePickerShow2"
                type="custom"
                title="选择日期"
                large-radius
                :text-render="textRender"
                :custom-types="['yyyy', 'MM', 'dd']"
                :default-date="currentDate"
                @change="onDatePickerChange"
                @confirm="onDatePickerConfirm"
              ></md-date-picker>
              <!-- <md-input-item
              title="年龄"
              class="require"
              clearable
              type="digit"
              name="apidage"
              v-validate="'required'"
              :error="errors.first('apidage')"
              v-model="from.customer.apidage"
              maxlength="2"
              placeholder="请填写真实年龄"
              align="right"
            ></md-input-item> -->
            </md-field>
          </div>
        </div>
      </div>
      <div class="xian_message">
        <div class="coverage_info">
          <div
            :style="{ '--color': getStorage('theme') }"
            class="message-top wbtop"
          >
            <div class="leftbox">
              <span class="redbg"></span>
              <p>险种信息</p>
            </div>
          </div>
          <div v-for="(item, index) of cost" :key="index" class="fle">
            <div class="all">
              <span :class="{ detali_del: index == 0 }" class="info_name">{{
                item.prodname
              }}</span>
              <span
                v-if="index != 0"
                @click="del_xianzhong(index)"
                class="del"
                :style="{ color: getStorage('theme') }"
                >删除</span
              >
              <span
                v-if="index != 0"
                class="g_k"
                :style="{ color: getStorage('theme') }"
              >
                |
              </span>
              <span
                :style="{ color: getStorage('theme') }"
                @click="open_popup(item, index)"
                class="update_message-top"
                >添加附加险</span
              >
            </div>
            <div class="shou_money">首年保费：{{ item.allcost }}元</div>
            <table @click="open_popup(item, index)">
              <thead>
                <tr>
                  <td>险种</td>
                  <td>保额</td>
                  <td>保费</td>
                  <td>交费期</td>
                </tr>
                <tr>
                  <td>
                    <div class="img_td">
                      <img src="@/assets/jhs/img/zhu_zhu.png" alt="" />
                      <div>{{ item.prodname }}</div>
                    </div>
                  </td>
                  <td>
                    {{
                      item.utilmount >= 10000
                        ? item.utilmount / 10000 + "万"
                        : parseInt(item.utilmount)
                    }}
                  </td>
                  <td>{{ item.cost }}</td>
                  <td>{{ item.yearnum }}</td>
                </tr>
              </thead>
              <tbody v-for="(it, ind) of item.fjlist" :key="ind">
                <tr>
                  <td>
                    <div class="img_td">
                      <img src="@/assets/jhs/img/zhu_fu.png" alt="" />
                      <div>{{ it.prodname }}</div>
                    </div>
                  </td>
                  <td>
                    {{
                      it.utilmount >= 10000
                        ? it.utilmount / 10000 + "万"
                        : parseInt(it.utilmount)
                    }}
                  </td>
                  <td>{{ it.cost }}</td>
                  <td>{{ it.yearnum }}</td>
                </tr>
              </tbody>
            </table>
            <md-popup class="popup" v-model="popupisshow" position="bottom">
              <div class="popup_title">
                <md-popup-title-bar
                  :title="showobj.prodname"
                  @confirm="hidePopUp('bottom')"
                  @cancel="hidePopUp('bottom')"
                ></md-popup-title-bar>
              </div>
              <div class="info_info">
                <div class="message">
                  <div class="message-top">
                    <img src="@/assets/jhs/img/zhu.png" alt />
                    <p>{{ showobj.prodname }}</p>
                    <md-agree
                      :disabled="disabled"
                      :style="{ '--color': getStorage('theme') }"
                      v-model="showobj.falg"
                      size="lg"
                      @change="
                        onChange(showobj.falg, showobj.fpordlist, $event)
                      "
                    ></md-agree>
                  </div>
                  <div class="message-bom">
                    <div class="message-bompd">
                      <md-field-item
                        :title="
                          showobj.isreversal != 1
                            ? '年缴保费'
                            : showobj.isfixed == 0
                            ? '保险金额(固定)'
                            : '保险金额'
                        "
                        class="rightje"
                        v-if="!showobj.ishasbe"
                      >
                        <span v-if="showobj.isfixed == 0">{{
                          showobj.initmoney
                        }}</span>
                        <input
                          v-model="showobj.initmoney"
                          onkeyup="value=value.replace(/[^\d]/g,'')"
                          placeholder="请输入保险金额"
                          style="
                            text-align: right;
                            font-size: 15px;
                            width: 3rem;
                          "
                          v-else
                        />
                      </md-field-item>
                      <md-field-item
                        title="缴费年限"
                        :content="showobj.endnum"
                        @click="showSelector(showobj, from.prodlist[index])"
                        arrow="arrow-right"
                        align="right"
                        :solid="false"
                      />
                      <md-field-item
                        title="保障方案"
                        v-if="showobj.jhname && showobj.classjh.length > 1"
                        :content="showobj.jhname"
                        @click="selectzxjh(showobj, from.prodlist[index])"
                        arrow="arrow-right"
                        align="right"
                        :solid="false"
                      />
                      <div
                        v-for="(ieffdata, ieff) of showobj.effinfo"
                        :key="ieff"
                      >
                        <md-field-item
                          :title="ieffdata.name"
                          :content="ieffdata.value"
                          @click="
                            showieffdata(ieffdata, from.prodlist[index], ieff)
                          "
                          arrow="arrow-right"
                          align="right"
                          :solid="false"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="message"
                    v-for="(ic, i) of showobj.fpordlist"
                    :key="i"
                  >
                    <div class="message-top">
                      <img src="@/assets/jhs/img/fu.png" alt />
                      <p>{{ ic.fprodname }}</p>
                      <md-agree
                        :style="{ '--color': getStorage('theme') }"
                        v-model="ic.falg"
                        :disabled="ic.notouch"
                      ></md-agree>
                    </div>
                    <div class="message-bom">
                      <div class="message-bompd">
                        <md-field-item
                          :title="
                            ic.isreversal != 1
                              ? '年缴保费'
                              : ic.isfixed == 0
                              ? '保险金额(固定)'
                              : '保险金额'
                          "
                          class="rightje"
                          v-if="!ic.ishasbe"
                        >
                          <span v-if="ic.isfixed == 0">{{ ic.initmoney }}</span>
                          <input
                            v-model="ic.initmoney"
                            onkeyup="value=value.replace(/[^\d]/g,'')"
                            placeholder="请输入保险金额"
                            v-else
                            style="
                              text-align: right;
                              font-size: 15px;
                              width: 3rem;
                            "
                          />
                        </md-field-item>
                        <md-field-item
                          title="缴费年限"
                          :content="ic.endnum"
                          @click="showSelect(ic, from.prodlist[index], i)"
                          arrow="arrow-right"
                          align="right"
                          :solid="false"
                        />
                        <md-field-item
                          title="保障方案"
                          v-if="ic.jhname && ic.classjh.length > 1"
                          :content="ic.jhname"
                          @click="selectjh(ic, from.prodlist[index], i)"
                          arrow="arrow-right"
                          align="right"
                          :solid="false"
                        />
                        <div v-for="(ieffdata, ieff) of ic.effinfo" :key="ieff">
                          <md-field-item
                            :title="ieffdata.name"
                            :content="ieffdata.value"
                            @click="
                              showiedata(
                                ieffdata,
                                from.prodlist[index],
                                i,
                                ieff
                              )
                            "
                            arrow="arrow-right"
                            align="right"
                            :solid="false"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 影响因子 -->
                <md-selector
                  v-model="isieffdata"
                  default-value="1"
                  :data="ieffdata.data"
                  max-height="30vh"
                  min-height="30vh"
                  title="请选择"
                  large-radius
                  @choose="onSelectieff($event, ieffdataindex, ieffdatai)"
                ></md-selector>
                <!-- 主险缴费年限 -->
                <md-selector
                  v-model="isSelectorShow"
                  default-value="2"
                  :data="list.eyearnum"
                  max-height="30vh"
                  min-height="30vh"
                  title="缴费年限"
                  large-radius
                  @choose="onSelectorChoose($event, listindex)"
                ></md-selector>
                <!-- 附加险缴费年限 -->
                <md-selector
                  v-model="isSelecShow"
                  default-value="2"
                  :data="listic.feyearnum"
                  title="缴费年限"
                  max-height="30vh"
                  min-height="30vh"
                  large-radius
                  @choose="onselectValue($event, listicindex, listici)"
                ></md-selector>
                <!-- 主险保障方案 -->
                <md-selector
                  v-model="isSeleczxJhShow"
                  default-value="2"
                  :data="list.classjh"
                  title="保障方案"
                  max-height="30vh"
                  min-height="30vh"
                  large-radius
                  @choose="onselectzxjh($event, listindex)"
                ></md-selector>
                <!-- 附加险保障方案 -->
                <md-selector
                  v-model="isSelecJhShow"
                  default-value="2"
                  :data="listic.classjh"
                  title="保障方案"
                  max-height="30vh"
                  min-height="30vh"
                  large-radius
                  @choose="onselectfjh($event, listicindex, listici)"
                ></md-selector>
                <!-- 附加险缴费年限 -->
                <md-selector
                  v-model="isieffdatas"
                  default-value="2"
                  :data="ieffdatas.data"
                  title="请选择"
                  max-height="30vh"
                  min-height="30vh"
                  large-radius
                  @choose="
                    onSelectieffs(
                      $event,
                      ieffdataindexs,
                      ieffdatais,
                      ieffisieff
                    )
                  "
                ></md-selector>
              </div>
              <!-- </div> -->
              <div style="margin-bottom: 2rem"></div>
              <div class="sure_info">
                <md-button
                  :style="{
                    backgroundColor: getStorage('theme'),
                  }"
                  @click="click_sure"
                  class="sure_info_button"
                  :type="type"
                  >确定</md-button
                >
              </div>
            </md-popup>
          </div>
          <div
            :style="{
              color: getStorage('theme'),
              borderColor: getStorage('theme'),
            }"
            @click="pro(), getData('click')"
            class="add"
          >
            ＋ 添加主险
          </div>
          <md-popup class="popup_xzcp" v-model="product" position="bottom">
            <md-popup-title-bar
              title="选择产品"
              cancel-text="×"
              only-close
              @confirm="hidePopUp"
              @cancel="hidePopUp1"
            ></md-popup-title-bar>
            <div class="popup_product">
              <div class="search_text">
                <input
                  v-model="searchValue"
                  placeholder="搜索产品"
                  type="text"
                  v-on:focus="getFocus"
                />
                <button
                  class="btnclear"
                  @click="clearinput"
                  v-show="isclear"
                  style="background-color: #f2f2f2"
                >
                  <md-icon name="fail" size="lg" color="#666"></md-icon>
                </button>
                <md-icon name="search" size="lg" color="#D6D6D6"></md-icon>
                <span @click="yourmethod">搜索</span>
              </div>
              <div class="baoxian_xainzhong">
                <div>
                  <div
                    @click="choosefl(item2, index2)"
                    :style="{
                      color:
                        changeSelectStyle == index2
                          ? getStorage('theme')
                          : '#6B6B6B',
                    }"
                    :class="{ change_color: changeSelectStyle == index2 }"
                    v-for="(item2, index2) of getStorage('jihuashu_shortName')"
                    :key="index2"
                  >
                    {{ item2.shortname }}
                  </div>
                </div>
                <div style="height: 63vh">
                  <div v-show="reportList.length == 0">
                    <img
                      class="if_image"
                      src="../../../../assets/image/null.png"
                      alt=""
                    />
                  </div>
                  <md-scroll-view
                    class="scroll"
                    ref="scrollView"
                    auto-reflow
                    @end-reached="loadMorechange"
                    :scrolling-x="false"
                    v-show="reportList.length != 0"
                  >
                    <md-check-group v-model="prods">
                      <div
                        class="pro_name"
                        v-for="(item, index) of reportList"
                        :key="index"
                        @click="addition(item)"
                      >
                        {{ item.prodname }}
                      </div>
                    </md-check-group>
                    <md-scroll-view-more
                      slot="more"
                      :is-finished="listFinished"
                    />
                  </md-scroll-view>
                </div>
              </div>
            </div>
          </md-popup>
        </div>
      </div>
    </div>
    <div class="messagelist-bom">
      <div class="messagelist-bomlf">
        <div class="zongbaofei">首年总保费</div>
        <div :style="{ color: getStorage('theme') }" v-html="baofei_zong"></div>
      </div>
      <div class="messagelist-bomrg">
        <md-button
          :style="{ backgroundColor: getStorage('theme') }"
          type="primary"
          @click="submit"
          :loading="btnloading"
          :inactive="inactive"
          >生成计划书</md-button
        >
      </div>
    </div>
    <div v-if="infod.isshowd == true" class="tsinfo">{{ infod.textd }}</div>
  </div>
</template>
<script>
import {
  getcpinfolist,
  infolist,
  prospectus,
  totlebaofei,
  useJhsInfo,
} from "@/api/jhs/productlist/index";
import {
  RadioList,
  Stepper,
  Field,
  FieldItem,
  Agree,
  Check,
  CheckGroup,
  Toast,
  Selector,
  DatePicker,
  Popup,
  PopupTitleBar,
  Button,
} from "mand-mobile";
import { getStorage, stopScroll, canScroll, isAndroid } from "@/lib/util";
import loadMorechange from "@/mixins/loadMorechange";
export default {
  mixins: [loadMorechange],
  data() {
    return {
      // 当code为250时的提示
      infod: {
        isshowd: false,
        textd: "",
      },
      Myjhs_baseinfo: {}, //我的计划书的基本信息
      res_code: "", //报错的code
      res_msg: "", //报错时返的信息
      prodcode: "",
      addtion: false,
      click: false,
      type: "",
      disabled: false,
      color: {
        color: getStorage("theme"),
      },
      dataa: {},
      cpname: this.$route.query.cpname,
      is_showx: true,
      is_shown: true,
      is_showxn: true,
      isshou_tou: true,
      newyear: "",
      newmonth: "",
      newday: "",
      records: [],
      formlist: {
        baseid: "",
        cpname: "",
      },
      changeSelectStyle: "",
      product: false,
      allcost: "0.00", //总保费
      cost: [], //各个险种的保费
      isPopupShow1: false,
      isPopupShow2: false,
      // myReason: "16岁",
      // pidage: "18岁",
      myReason2: "16",
      myReason3: "18",
      reasons: [],
      reasons1: [],
      agee: false,
      ageee: false,
      agee1: false,
      ageee1: false,
      checked: 1,
      maxDate1: new Date("2021/12/12"), //修改被保人最大生日
      maxDate: new Date("2005/12/12"), //被保人最大生日
      amaxDate: new Date("2021/12/12"), //投保人最大生日
      aminDate1: new Date("1949/1/1"), //最小生日
      amaxDate1: new Date("2005/12/12"), //修改投保人最大生日
      currentDate: new Date(),
      isDatePickerShow2: false,
      isDatePickerShow1: false,
      isDatePickerShow3: false,
      isDatePickerShow4: false,
      datePickerValue2: "", //投保人生日
      datePickerValue1: "", //被保人生日
      datePickerValue3: "", //修改投保人生日
      datePickerValue4: "", //修改被保人生日
      ieffdatatrue: "",
      btnloading: false,
      loading: false,
      inactive: false,
      from: {
        customer: {
          apidname: "", //投保人名字
          apidsex: "男", // 投保人性别
          apidsex1: "男", // 修改被保人性别
          apidsex2: "男", // 修改投保人性别
          pidname: "", //被保人名字
          apidage: "32", // 投保人年龄
          pidage: "32", // 被保人年龄
          pidsex: "男", // 被保人性别
        },
        prodlist: [],
      },
      ieffdatais: "",
      ieffdatas: [], // 主险区间
      ieffdataindexs: "", // 主险对应坐标
      ieffdatai: "",
      ieffdata: [], // 主险区间
      ieffdataindex: "", // 主险对应坐标
      list: [], // 主险区间
      listindex: "", // 主险对应坐标
      listic: [], // 附加险区间
      listicindex: "", // 附加险 主险对应坐标
      listici: "", // 附加险对应坐标
      reportList: [],
      prods: [],
      ishas: [], // 验证显示险种
      addform: {
        name: "",
        relation: "00",
        is: "是",
        birthdate: "",
        cardtype: "",
        cardno: "",
      },
      selectorValue: "",
      selectValue: "",
      isieffdatas: false,
      isieffdata: false,
      isSelectorShow: false,
      isSelecShow: false,
      isSelecJhShow: false,
      isSeleczxJhShow: false,
      isPopupShow: false, // 右侧
      value: 30000,
      ipontype: false,

      searchValue: "",
      isclear: false,
      digitgroup: [],
      transform: [],
      baofei: "",
      baofei_zong: "",
      loadflag: false,
      listFinished: false,
      listpageNo: 1,
      pageSize: 25,
      listtotal: 0,
      listtotalPage: 0,
      showobj: {},
      popupisshow: false, //是否弹出选择产品弹框
      arr: [], //返回主险缴费年限的数组 <+20的数
      farr: [], //返回附加险缴费年限的数组 <+20的数
      length: 0,
      fjxflag: "", // 接收附加险报错code
      use_programme_xzinfo: [],
      use_programme_echo: [],
      use_programme_jhsinfo: {},
      lsechofalg: false,
    };
  },
  components: {
    [Selector.name]: Selector,
    [Field.name]: Field,
    [FieldItem.name]: FieldItem,
    [Stepper.name]: Stepper,
    [Agree.name]: Agree, // 单选
    [Check.name]: Check, // 多选
    [CheckGroup.name]: CheckGroup, // 多选
    [Toast.component.name]: Toast.component,
    [DatePicker.name]: DatePicker,
    [RadioList.name]: RadioList,
    [Popup.name]: Popup,
    [PopupTitleBar.name]: PopupTitleBar,
    [Button.name]: Button,
  },
  created() {
    let splitPrice = this.allcost.split(".");
    this.baofei = `<span>首年保费：</span><span style="font-size:0.58rem;">${splitPrice[0]}</span>.${splitPrice[1]}元`;
    this.baofei_zong = `<span style="font-size:0.58rem;">${splitPrice[0]}</span>.${splitPrice[1]}元`;
    for (let i = 0; i < 71; i++) {
      this.reasons.push({
        value: `${i}`,
        text: `${i}`,
      });
    }
    for (let i = 16; i < 71; i++) {
      this.reasons1.push({
        value: `${i}`,
        text: `${i}`,
      });
    }
    this.theme = getStorage("theme", "");
    this.user = getStorage("u_s", {});
    this.newyear = new Date().getFullYear();
    this.newmonth = new Date().getMonth() + 1;
    this.newday = new Date().getDate();
    if (this.$route.query.type == "1") {
      this.from.customer.apidname = this.Myjhs_baseinfo.apidname
        ? this.Myjhs_baseinfo.apidname
        : "";
      this.from.customer.pidname = this.Myjhs_baseinfo.pidname
        ? this.Myjhs_baseinfo.pidname
        : "";
      this.from.customer.apidsex = this.Myjhs_baseinfo.apidsex
        ? this.Myjhs_baseinfo.apidsex
        : "";
      this.from.customer.pidsex = this.Myjhs_baseinfo.pidsex
        ? this.Myjhs_baseinfo.pidsex
        : "";
      this.from.customer.apidage = this.Myjhs_baseinfo.apidage
        ? this.Myjhs_baseinfo.apidage
        : "";
      this.from.customer.pidage = this.Myjhs_baseinfo.pidage
        ? this.Myjhs_baseinfo.pidage
        : "";
      this.datePickerValue2 = this.Myjhs_baseinfo.apibirthday
        ? this.Myjhs_baseinfo.apibirthday
        : "";
      this.datePickerValue3 = this.Myjhs_baseinfo.apibirthday
        ? this.Myjhs_baseinfo.apibirthday
        : "";
      this.datePickerValue1 = this.Myjhs_baseinfo.pidbirthday
        ? this.Myjhs_baseinfo.pidbirthday
        : "";
      this.datePickerValue4 = this.Myjhs_baseinfo.pidbirthday
        ? this.Myjhs_baseinfo.pidbirthday
        : "";
      let isrealresson =
        this.from.customer.apidname == this.from.customer.pidname &&
        this.from.customer.apidsex == this.from.customer.pidsex &&
        this.from.customer.apidage == this.from.customer.pidage &&
        this.datePickerValue2 == this.datePickerValue1;
      if (isrealresson) {
        this.addform.is = "是";
      } else {
        this.addform.is = "否";
      }

      // 如果是从历史计划书列表点进来，需要调接口拿历史数据
      if (this.$route.query.jhsno) {
        useJhsInfo({ jhsno: this.$route.query.jhsno }).then((res) => {
          this.use_programme_xzinfo =
            res.data.data.xzinfo && res.data.data.echofalg == false
              ? res.data.data.xzinfo
              : [];
          this.use_programme_echo =
            res.data.data.echo && res.data.data.echofalg == true
              ? res.data.data.echo.prodlist
              : [];
          this.use_programme_jhsinfo = res.data.data.jhsinfo
            ? res.data.data.jhsinfo
            : {};
          this.Myjhs_baseinfo = this.use_programme_jhsinfo;
          this.lsechofalg = res.data.data.echofalg;
          res.data.data.echo.equal == "1"
            ? (this.addform.is = "否")
            : (this.addform.is = "是");
          this.from.prodlist = JSON.parse(
            JSON.stringify(
              this.lsechofalg == false
                ? this.use_programme_xzinfo
                : this.use_programme_echo
            )
          );
          this.click_sure();
        });
      } else {
        this.click_sure();
      }
    } else {
      this.cre_cpinfolist();
    }
  },
  watch: {
    // "addform.is"(val) {
    //   if (val == "是") {
    //     this.from.customer.apidsex = this.from.customer.pidsex;
    //     this.from.customer.apidname = this.from.customer.pidname;
    //     this.from.customer.apidage = this.from.customer.pidage;
    //     this.datePickerValue2 = this.datePickerValue1;
    //   }
    // },
    prods(val) {
      this.digitgroup = val;
      this.getgroup();
    },
    "from.customer.pidsex"() {
      deep: true;
      if (this.addform.is == "是") {
        this.from.customer.apidsex = this.from.customer.pidsex;
      }
    },
    "from.customer.pidname"() {
      deep: true;
      if (this.addform.is == "是") {
        this.from.customer.apidname = this.from.customer.pidname;
      }
    },
    "from.customer.pidage"() {
      deep: true;
      if (this.addform.is == "是") {
        this.from.customer.apidage = this.from.customer.pidage;
      }
    },
    datePickerValue1() {
      deep: true;
      if (this.addform.is == "是") {
        this.datePickerValue2 = this.datePickerValue1;
      }
    },
    isPopupShow(val) {
      if (val) {
        this.prods = [];
        stopScroll();
      } else {
        this.prods = [];
        canScroll();
      }
    },
  },
  methods: {
    //弹出选择产品弹框
    pro() {
      this.product = true;
      this.reportList.splice(this.reportList.length - this.length, this.length);
      this.listpageNo = 1;
    },
    //修改弹框
    open_popup(item, index) {
      item.isshow = true;
      this.showobj = this.from.prodlist[index];
      this.popupisshow = true;
      this.disabled = true;
    },
    //删除当前险种并重新调用计算首年总保费接口
    del_xianzhong(index) {
      // this.ishas = [...new Set(this.ishas)];
      this.showobj = this.from.prodlist;
      this.showobj.splice(index, 1);
      this.cost.splice(index, 1);
      this.click_sure("cre");
      this.ishas.splice(index, 1);
    },
    //同投被人按钮
    dis_all() {
      this.is_showx = !this.is_showx;
      this.is_showxn = !this.is_showxn;
    },
    // “否” 按钮
    noshow_xn() {
      this.is_showx = true;
      this.is_shown = true;
      this.from.customer.apidname = "";
      this.datePickerValue2 = "";
      console.log(this.showobj);
      for (let i = 0; i < this.showobj.fpordlist.length; i++) {
        if (this.showobj.fpordlist[i].fprodname.indexOf("豁免") != -1) {
          // console.log(i)
          this.showobj.fpordlist[i].falg = false;
          this.$set(this.showobj.fpordlist[i], "notouch", false);
        }
      }
    },
    // “是” 按钮
    isshow_xn() {
      this.from.customer.apidsex = this.from.customer.pidsex;
      this.from.customer.apidage = this.from.customer.pidage;
      this.from.customer.apidname = this.from.customer.pidname;
      this.datePickerValue2 = this.datePickerValue1;
      this.is_showx = false;
      this.is_shown = false;
      console.log(this.showobj);
      for (let i = 0; i < this.showobj.fpordlist.length; i++) {
        if (this.showobj.fpordlist[i].fprodname.indexOf("豁免") != -1) {
          // console.log(i)
          this.showobj.fpordlist[i].falg = false;
          this.$set(this.showobj.fpordlist[i], "notouch", true);
        }
      }
    },
    // 改变点击后的样式
    choosefl(item2, index2) {
      this.changeSelectStyle = index2;
      this.formlist.baseid = item2.baseid;
      this.listFinished = false;
      this.loadflag = false;
      this.$refs.scrollView.finishLoadMore();
      this.listpageNo = 1;
      this.reportList = [];
      this.getData();
    },
    // 计算首年总保费
    click_sure(type, cli) {
      this.popupisshow = false;
      this.$validator.validate().then((valid) => {
        if (valid) {
          let falg = false; // 判断主副险
          for (let item in this.from.prodlist) {
            if (this.from.prodlist[item].falg) {
              falg = true;
              break;
            }
          }
          if (type != "cre") {
            if (cli != "click") {
              this.from.prodlist.push(this.showobj);
              this.from.prodlist = [...new Set(this.from.prodlist)];
            } else {
              this.from.prodlist = this.from.prodlist;
            }
          }
          if (falg) {
            this.btnloading = true;
            this.inactive = true;
            this.from.cpcode = this.$route.query.cpcode;
            if (this.$route.query.type == "1") {
              this.from.customer = this.use_programme_jhsinfo;
              this.from.prodlist =
                this.lsechofalg == false
                  ? this.use_programme_xzinfo
                  : this.use_programme_echo;
              // --------------影响因子---------------
              for (let item in this.from.prodlist) {
                this.from.prodlist.forEach((v) => {
                  for (let it in v.effinfo) {
                    v.effinfo[it].value =
                      this.lsechofalg == true
                        ? v.effinfo[it].value
                        : v.effinfo[it].data[0].value;
                  }
                  this.arr = [];
                  v.jhname = v.classjh.length > 0 ? v.classjh[0].jhname : "";
                  v.jhcode = v.classjh.length > 0 ? v.classjh[0].jhcode : "";
                  v.endnum =
                    this.lsechofalg == true ? v.endnum : v.eyearnum[0].label;
                  for (let item in v.fpordlist) {
                    v.fpordlist[item].initmoney = parseInt(
                      v.fpordlist[item].initmoney
                    );
                    v.fpordlist[item].jhname =
                      v.fpordlist[item].classjh.length > 0
                        ? v.fpordlist[item].classjh[0].jhname
                        : "";
                    v.fpordlist[item].jhcode =
                      v.fpordlist[item].classjh.length > 0
                        ? v.fpordlist[item].classjh[0].jhcode
                        : "";
                    this.farr = [];
                    v.fpordlist[item].endnum =
                      this.lsechofalg == true
                        ? v.fpordlist[item].endnum
                        : v.eyearnum[0].label;
                    for (let ic in v.fpordlist[item].effinfo) {
                      v.fpordlist[item].effinfo[ic].value =
                        this.lsechofalg == true
                          ? v.fpordlist[item].effinfo[ic].value
                          : v.fpordlist[item].effinfo[ic].data[0].value;
                    }
                  }
                });
                this.showobj = this.from.prodlist[item];
                this.showobj.initmoney = parseInt(
                  this.from.prodlist[item].initmoney
                );
              }
              // ------------------------影响因子--------------------------------\
              this.$route.query.type = "";
            }
            totlebaofei({
              ...this.from,
              empno: this.user.empno,
              comid: this.user.comid,
            })
              .then((res) => {
                if (type != "cre") {
                  if (res.data.code != "200") {
                    if (res.data.code == "250") {
                      this.infod.textd = res.data.msg;
                      // Toast.failed(res.data.msg);
                      this.infod.isshowd = true;
                      setTimeout(() => {
                        this.infod.isshowd = false;
                      }, 2000);
                    }
                    if (cli != "click" || cli == "click") {
                      this.fjxflag = JSON.parse(res.data.data).prodcode;
                    }
                    let is_has = this.ishas.indexOf(this.prodcode);
                    if (
                      this.from.prodlist.length > 1 &&
                      cli != "click" &&
                      JSON.parse(res.data.data).appf != "2" &&
                      this.cost.length == this.from.prodlist.length
                    ) {
                      this.from.prodlist.splice(
                        this.from.prodlist.length - 1,
                        1
                      );
                    } else if (
                      this.from.prodlist.length > 1 &&
                      cli != "click" &&
                      JSON.parse(res.data.data).appf &&
                      this.cost.length != this.from.prodlist.length
                    ) {
                      this.from.prodlist.splice(
                        this.from.prodlist.length - 1,
                        1
                      );
                    }
                    this.from.prodlist.forEach((item, index) => {
                      item.fpordlist.forEach((it) => {
                        if (this.fjxflag == it.fprodcode) {
                          it.falg = false;
                        }
                      });
                    });
                  } else if (res.data.code == "200") {
                    if (cli != "click") {
                      this.ishas.push(this.showobj.prodcode);
                      this.ishas = [...new Set(this.ishas)];
                    }
                  }
                }
                this.allcost = res.data.data.allcost;
                if (res.data.data.costlist) {
                  this.cost = res.data.data.costlist;
                }
                let splitPrice = this.allcost.split(".");
                this.baofei = `<span>首年保费：</span><span style="font-size:0.58rem;">${splitPrice[0]}</span>.${splitPrice[1]}元`;
                this.baofei_zong = `<span style="font-size:0.58rem;">${splitPrice[0]}</span>.${splitPrice[1]}元`;
              })
              .catch((e) => {
                console.log(e);
              })
              .finally(() => {
                this.btnloading = false;
                this.inactive = false;
              });
          } else {
            Toast.failed("最少选择一个主险");
          }
        } else {
          Toast.failed("请把信息填写完整!");
        }
      });
    },
    // 保存按钮后弹框消失
    baocun_bei() {
      this.datePickerValue1 = this.datePickerValue4;
      this.from.customer.pidage = this.myReason3;
      this.from.customer.pidsex = this.from.customer.apidsex2;
      this.isPopupShow2 = false;
      this.click_sure("", "click");
    },
    baocun_tou() {
      this.datePickerValue2 = this.datePickerValue3;
      this.from.customer.apidage = this.myReason2;
      this.from.customer.apidsex = this.from.customer.apidsex1;
      this.isPopupShow1 = false;
      this.is_showxn = true;
      this.is_showx = true;
    },
    onSelectorChoose1({ text }) {
      this.from.customer.apidage = text;
      this.datePickerValue2 = "";
    },
    onSelectorChoose2({ text }) {
      this.from.customer.pidage = text;
      this.datePickerValue1 = "";
      this.click_sure("", "click");
    },
    onSelectorChoose3({ text }) {
      this.myReason2 = text;
      this.datePickerValue3 = "";
    },
    onSelectorChoose4({ text }) {
      this.myReason3 = text;
      this.datePickerValue4 = "";
    },
    showPopUp(type) {
      this.isPopupShow1 = true;
      this.datePickerValue3 = this.datePickerValue2;
      this.myReason2 = this.from.customer.apidage;
      this.from.customer.apidsex1 = this.from.customer.apidsex;
    },
    hidePopUp(type) {
      this.$set(this.isPopupShow1, type, false);
    },
    showPop(type) {
      this.isPopupShow2 = true;
      this.datePickerValue4 = this.datePickerValue1;
      this.myReason3 = this.from.customer.pidage;
      this.from.customer.apidsex2 = this.from.customer.pidsex;
    },
    hidePop(type) {
      this.$set(this.isPopupShow2, type, false);
    },
    textRender() {
      const args = Array.prototype.slice.call(arguments);
      const typeFormat = args[0]; // 类型
      const column2Value = args[3]; // 第3列选中值
      if (typeFormat === "dd") {
        return `${column2Value}日`;
      }
    },
    onDatePickerChange(columnIndex, itemIndex, value) {
      console.log(
        `[Mand Mobile] DatePicker Change\ncolumnIndex: ${columnIndex},\nitemIndex:${itemIndex},\nvalue: ${JSON.stringify(
          value
        )}`
      );
    },
    onDatePickerConfirm(columnsValue) {
      let year = this.newyear - columnsValue[0].value;
      let month = this.newmonth - columnsValue[1].value;
      let day = this.newday - columnsValue[2].value;
      if (month >= 0 && year >= 0) {
        this.from.customer.apidage = year;
        if (day >= 0 && month >= 0) {
          this.from.customer.apidage = year;
        } else {
          this.from.customer.apidage = year;
        }
      } else {
        this.from.customer.apidage = year;
      }
      this.datePickerValue2 = this.$refs.datePicker.getFormatDate("yyyy/MM/dd");
    },
    onDatePickerChange1(columnIndex, itemIndex, value) {
      console.log(
        `[Mand Mobile] DatePicker1 Change\ncolumnIndex: ${columnIndex},\nitemIndex:${itemIndex},\nvalue: ${JSON.stringify(
          value
        )}`
      );
    },
    onDatePickerConfirm1(columnsValue) {
      this.click_sure("cre");
      let year = this.newyear - columnsValue[0].value;
      let month = this.newmonth - columnsValue[1].value;
      let day = this.newday - columnsValue[2].value;
      if (month >= 0 && year >= 0) {
        this.from.customer.pidage = year;
        if (day >= 0 && month >= 0) {
          this.from.customer.pidage = year;
        } else {
          this.from.customer.pidage = year;
        }
      } else {
        this.from.customer.pidage = year;
      }
      this.datePickerValue1 = this.$refs.datePicker1.getFormatDate(
        "yyyy/MM/dd"
      );
    },
    onDatePickerChange2(columnIndex, itemIndex, value) {
      console.log(
        `[Mand Mobile] DatePicker2 Change\ncolumnIndex: ${columnIndex},\nitemIndex:${itemIndex},\nvalue: ${JSON.stringify(
          value
        )}`
      );
    },
    onDatePickerConfirm2(columnsValue) {
      let year = this.newyear - columnsValue[0].value;
      let month = this.newmonth - columnsValue[1].value;
      let day = this.newday - columnsValue[2].value;
      if (month >= 0 && year >= 0) {
        this.myReason2 = year;
        if (day >= 0 && month >= 0) {
          this.myReason2 = year;
        } else {
          this.myReason2 = year;
        }
      } else {
        this.myReason2 = year;
      }
      this.datePickerValue3 = this.$refs.datePicker2.getFormatDate(
        "yyyy/MM/dd"
      );
    },
    onDatePickerChange3(columnIndex, itemIndex, value) {
      console.log(
        `[Mand Mobile] DatePicker3 Change\ncolumnIndex: ${columnIndex},\nitemIndex:${itemIndex},\nvalue: ${JSON.stringify(
          value
        )}`
      );
    },
    onDatePickerConfirm3(columnsValue) {
      let year = this.newyear - columnsValue[0].value;
      let month = this.newmonth - columnsValue[1].value;
      let day = this.newday - columnsValue[2].value;
      if (month >= 0 && year >= 0) {
        this.myReason3 = year;
        if (day >= 0 && month >= 0) {
          this.myReason3 = year;
        } else {
          this.myReason3 = year;
        }
      } else {
        this.myReason3 = year;
      }
      this.datePickerValue4 = this.$refs.datePicker3.getFormatDate(
        "yyyy/MM/dd"
      );
    },
    getgroup() {
      this.transform = [];
      this.digitgroup.forEach((itema, indexa) => {
        this.reportList.forEach((item, index) => {
          if (itema == item.prodcode) {
            this.transform.push(item);
          }
        });
      });
    },
    getFocus() {
      this.isclear = true;
    },
    clearinput() {
      this.searchValue = "";
      this.isclear = false;
    },
    hidePopUp() {
      this.isPopupShow = false;
    },
    hidePopUp1() {
      this.product = false;
    },
    yourmethod() {
      this.reportList = [];
      this.pageNo = 1;
      this.getData();
    },
    onChange(checked, children) {
      if (!checked) {
        // Toast({
        //   content: "请选择一个主险",
        //   position: "center",
        // });
        // this.type = "disabled";
        for (let item in children) {
          children[item].falg = false;
        }
      } else {
        this.type = "primary";
      }
    },
    cpinfolist(type) {
      let data = {
        comid: this.user.comid,
        prods: type === "add" ? this.prods : [this.$route.query.cpcode],
        type: type === "add" ? "" : this.$route.query.type,
        equal: this.addform.is == "是" ? "0" : undefined,
      };
      this.inactive = true;
      getcpinfolist(data).then((res) => {
        this.prodcode = res.data.data[0].prodcode;
        // 遍历和md-selector直接挂钩
        for (let item in res.data.data) {
          res.data.data.forEach((v) => {
            for (let item in v.effinfo) {
              v.effinfo[item].value = v.effinfo[item].data[0].value;
            }
            this.arr = [];
            // 缴费年限为20
            // for (let it in v.eyearnum) {
            //   if (v.eyearnum[it].value <= 20) {
            //     let mubiao = v.eyearnum[it].value - 20;
            //     this.arr.push(Math.abs(mubiao));
            //   }
            //   var xiabiao = this.arr.indexOf(Math.min(...this.arr));
            // }
            v.jhname = v.classjh.length > 0 ? v.classjh[0].jhname : "";
            v.jhcode = v.classjh.length > 0 ? v.classjh[0].jhcode : "";
            v.endnum = v.eyearnum[0].label;
            for (let item in v.fpordlist) {
              v.fpordlist[item].initmoney = parseInt(
                v.fpordlist[item].initmoney
              );
              v.fpordlist[item].jhname =
                v.fpordlist[item].classjh.length > 0
                  ? v.fpordlist[item].classjh[0].jhname
                  : "";
              v.fpordlist[item].jhcode =
                v.fpordlist[item].classjh.length > 0
                  ? v.fpordlist[item].classjh[0].jhcode
                  : "";
              this.farr = [];
              // 缴费年限为20
              // for (let itm in v.fpordlist[item].feyearnum) {
              //   if (v.fpordlist[item].feyearnum[itm].value <= 20) {
              //     let mubiao = v.fpordlist[item].feyearnum[itm].value - 20;
              //     this.farr.push(Math.abs(mubiao));
              //   }
              //   var xiabiao = this.farr.indexOf(Math.min(...this.farr));
              // }
              v.fpordlist[item].endnum = v.fpordlist[item].feyearnum[0].label;
              for (let ic in v.fpordlist[item].effinfo) {
                v.fpordlist[item].effinfo[ic].value =
                  v.fpordlist[item].effinfo[ic].data[0].value;
              }
            }
          });
          this.showobj = res.data.data[item];
          this.showobj.initmoney = parseInt(res.data.data[item].initmoney);
        }
        if (this.addform.is == "是") {
          for (let i = 0; i < this.showobj.fpordlist.length; i++) {
            if (this.showobj.fpordlist[i].fprodname.indexOf("豁免") != -1) {
              // console.log(i)
              this.showobj.fpordlist[i].falg = false;
              this.$set(this.showobj.fpordlist[i], "notouch", true);
            }
          }
        } else {
          for (let i = 0; i < this.showobj.fpordlist.length; i++) {
            if (this.showobj.fpordlist[i].fprodname.indexOf("豁免") != -1) {
              // console.log(i)
              this.showobj.fpordlist[i].falg = false;
              this.$set(this.showobj.fpordlist[i], "notouch", false);
            }
          }
        }
        this.isPopupShow = false;
        this.inactive = false;
      });
    },
    // 页面刚渲染时触发的方法用来计算保费
    cre_cpinfolist() {
      let data = {
        comid: this.user.comid,
        prods: [this.$route.query.cpcode],
        type: this.$route.query.type,
        equal: this.addform.is == "是" ? "0" : undefined,
      };
      this.inactive = true;
      getcpinfolist(data).then((res) => {
        // 遍历和md-selector直接挂钩
        for (let item in res.data.data) {
          this.ishas.push(res.data.data[item].prodcode);
          this.ishas = [...new Set(this.ishas)];
          this.showobj = res.data.data[item];
          this.showobj.initmoney = parseInt(res.data.data[item].initmoney);
        }
        if (this.addform.is == "是") {
          for (let i = 0; i < this.showobj.fpordlist.length; i++) {
            if (this.showobj.fpordlist[i].fprodname.indexOf("豁免") != -1) {
              // console.log(i)
              this.showobj.fpordlist[i].falg = false;
              this.$set(this.showobj.fpordlist[i], "notouch", true);
            }
          }
        } else {
          for (let i = 0; i < this.showobj.fpordlist.length; i++) {
            if (this.showobj.fpordlist[i].fprodname.indexOf("豁免") != -1) {
              // console.log(i)
              this.showobj.fpordlist[i].falg = false;
              this.$set(this.showobj.fpordlist[i], "notouch", false);
            }
          }
        }

        this.from.prodlist = res.data.data;
        this.from.prodlist.forEach((v) => {
          for (let item in v.effinfo) {
            v.effinfo[item].value = v.effinfo[item].data[0].value;
          }
          this.arr = [];
          // for (let it in v.eyearnum) {
          //   if (v.eyearnum[it].value <= 20) {
          //     let mubiao = v.eyearnum[it].value - 20;
          //     this.arr.push(Math.abs(mubiao));
          //   }
          //   var xiabiao = this.arr.indexOf(Math.min(...this.arr));
          // }
          v.jhcode =
            v.classjh && v.classjh.length > 0 ? v.classjh[0].jhcode : "";
          v.jhname =
            v.classjh && v.classjh.length > 0 ? v.classjh[0].jhname : "";
          v.endnum = v.eyearnum[0].label;
          for (let item in v.fpordlist) {
            v.fpordlist[item].initmoney = parseInt(v.fpordlist[item].initmoney);
            v.fpordlist[item].jhname =
              v.fpordlist[item].classjh.length > 0
                ? v.fpordlist[item].classjh[0].jhname
                : "";
            v.fpordlist[item].jhcode =
              v.fpordlist[item].classjh.length > 0
                ? v.fpordlist[item].classjh[0].jhcode
                : "";
            this.farr = [];
            // for (let itm in v.fpordlist[item].feyearnum) {
            //   if (v.fpordlist[item].feyearnum[itm].value <= 20) {
            //     let mubiao = v.fpordlist[item].feyearnum[itm].value - 20;
            //     this.farr.push(Math.abs(mubiao));
            //   }
            //   var xiabiao = this.farr.indexOf(Math.min(...this.farr));
            // }
            v.fpordlist[item].endnum = v.fpordlist[item].feyearnum[0].label;
            for (let ic in v.fpordlist[item].effinfo) {
              v.fpordlist[item].effinfo[ic].value =
                v.fpordlist[item].effinfo[ic].data[0].value;
            }
          }
          this.from.customer.pidage = v.minage
            ? v.minage
            : this.from.customer.pidage;
          this.from.customer.apidage = v.minage
            ? v.minage
            : this.from.customer.apidage;
        });
        this.inactive = false;
        this.click_sure("cre");
      });
    },
    submit() {
      // 生成计划书
      if (this.allcost == "0.00") {
        Toast.failed("最少选择一个主险");
        return;
      }
      this.$validator.validate().then((valid) => {
        if (valid) {
          let falg = false; // 判断主副险
          for (let item in this.from.prodlist) {
            if (this.from.prodlist[item].falg) {
              falg = true;
              break;
            }
          }
          if (falg) {
            this.btnloading = true;
            this.inactive = true;
            this.from.cpcode = this.$route.query.cpcode;
            var apibirthday =
              this.datePickerValue2 != ""
                ? this.datePickerValue2.replace(/\//g, "-")
                : null;
            var pidbirthday =
              this.datePickerValue1 != "?"
                ? this.datePickerValue1.replace(/\//g, "-")
                : null;
            this.from.prodlist.forEach((item, index) => {
              item.fpordlist.forEach((it) => {
                if (this.fjxflag == it.fprodcode) {
                  it.falg = false;
                }
              });
            });
            let data = {
              cpcode: this.from.cpcode,
              prodlist: this.from.prodlist,
              equal: this.addform.is == "是" ? "0" : undefined,
              customer: {
                pidage: this.from.customer.pidage,
                apidage: this.from.customer.apidage,
                pidname: this.from.customer.pidname,
                apidname: this.from.customer.apidname,
                pidsex: this.from.customer.pidsex,
                apidsex: this.from.customer.apidsex,
                apibirthday: apibirthday != "" ? apibirthday : null,
                pidbirthday: pidbirthday != "" ? pidbirthday : null,
              },
            };
            prospectus({ ...data })
              .then((res) => {
                if (res.data.code == "200") {
                  this.$router.push({
                    path: "/responsibility",
                    query: {
                      jhsno: res.data.data.jhsno,
                      fromwhere: this.$route.query.fromwhere
                        ? this.$route.query.fromwhere
                        : "",
                    },
                  });
                } else if (res.data.code == "250") {
                  this.infod.textd = res.data.msg;
                  this.infod.isshowd = true;
                  setTimeout(() => {
                    this.infod.isshowd = false;
                  }, 2000);
                }
              })
              .finally(() => {
                this.btnloading = false;
                this.inactive = false;
              });
          } else {
            Toast.failed("最少选择一个主险");
          }
        } else {
          Toast.failed("请把信息填写完整!");
        }
      });
    },
    addition(item) {
      this.disabled = true;
      this.addtion = true;
      this.type = "primary";
      this.prods = [];
      this.prods.push(item.prodcode);
      // 添加险种
      if (this.prods.length == 0) {
        Toast.failed("最少选择一个主险");
      } else {
        this.cpinfolist("add");
      }
      this.product = false;
      this.popupisshow = true;
    },
    getData(type) {
      //防止上拉加载完之后继续追加
      if (type == "click") {
        this.reportList = [];
      }
      let data = {
        page: this.listpageNo,
        size: this.pageSize,
        baseid: this.changeSelectStyle == "0" ? "" : this.formlist.baseid,
        ishas: this.ishas,
        prodname: this.searchValue,
        equal: this.addform.is == "是" ? "0" : undefined,
      };
      infolist(data).then((res) => {
        this.reportList = this.reportList.concat(res.data.data.records);
        this.length = res.data.data.records.length;
        this.listtotal = res.data.data.total;
        this.listtotalPage = res.data.data.pages;
        if (this.listtotalPage < this.listpageNo) {
          this.listFinished = true;
          // res.data.data.records = this.reportList;
        } else if (this.listtotalPage > this.listpageNo) {
          this.listFinished = false;
          this.$refs.scrollView.finishLoadMore();
        }
        this.loadflag = true;
      });
    },
    showieffdata(item, index, i) {
      this.ieffdatatrue = i + 1;
      this.ieffdata = item;
      this.ieffdataindex = index;
      this.ieffdatai = i;
      this.$forceUpdate();
      this.isieffdata = true;
    },
    showSelector(item, index) {
      this.list = item;
      this.listindex = index;
      this.isSelectorShow = true;
    },
    selectzxjh(item, index) {
      this.list = item;
      this.list.classjh.forEach((i) => {
        i.value = i.jhcode;
        i.label = i.jhname;
      });
      this.listindex = index;
      this.isSeleczxJhShow = true;
    },
    selectjh(ic, index, i) {
      this.listic = ic;
      this.listic.classjh.forEach((i) => {
        i.value = i.jhcode;
        i.label = i.jhname;
      });
      this.listicindex = index;
      this.listici = i;
      this.isSelecJhShow = true;
    },
    showSelect(ic, index, i) {
      this.listic = ic;
      this.listicindex = index;
      this.listici = i;
      this.isSelecShow = true;
    },
    showiedata(ic, index, i, ieff) {
      this.ieffdatas = ic;
      this.ieffdataindexs = index;
      this.ieffdatais = i;
      this.ieffisieff = ieff;
      this.isieffdatas = true;
    },
    // 影响因子
    onSelectieff({ label }, index, i) {
      this.showobj.effinfo[i].value = label;
    },
    //主险缴费年限
    onSelectorChoose({ label }, index) {
      this.showobj.endnum = label;
    },
    // 附加险缴费年限
    onselectValue({ label }, index, i) {
      this.showobj.fpordlist[i].endnum = label;
    },
    /* 主险计划下拉 */
    onselectzxjh({ label, value }, index) {
      this.showobj.jhname = label;
      this.showobj.jhcode = value;
    },
    /* 附加险计划下拉 */
    onselectfjh({ label, value }, index, i) {
      this.showobj.fpordlist[i].jhname = label;
      this.showobj.fpordlist[i].jhcode = value;
    },
    // 附加险缴费年限
    onSelectieffs({ label }, index, i, ieff) {
      this.showobj.fpordlist[i].effinfo[ieff].value = label;
    },
  },
};
</script>
<style lang="stylus" scoped>
.message-top {
  /deep/ .md-popup-title-bar .title-bar-title p.title {
    color: #666666;
  }
}

.icon /deep/ .md-icon.icon-font.md {
  color: #666666;
}

.btnclear {
  background-color: rgb(242, 242, 242);
  width: 0.3rem;
  position: absolute;
  right: 3.4rem;
  top: 0.05rem;
}

.popup_xzcp /deep/ .md-popup-box {
  overflow: hidden;
}

table {
  border-top: 1px solid #999;
  border-left: 1px solid #999;
  border-spacing: 0;
  width: 94%;
  text-align: center;
  align-items: center;
  color: #929191;
  line-height: 0.6rem;
  margin-top: 0.3rem;
  margin-left: 3%;

  tr {
    width: 100%;

    td:first-child {
      width: 3rem;
    }
  }
}

table td {
  padding: 0.05rem 0.1rem;
  border-bottom: 1px solid #999;
  border-right: 1px solid #999;
}

.md_safe .md-agree-icon .md-agree-icon-container, .if_image {
  margin-top: 40%;
  width: 60%;
  margin-left: 20%;
}

.display {
  display: inline-block;
  margin-left: 0.2rem;
}

/deep/ .md-agree-icon.checked .md-agree-icon-container .md-icon-checked, /deep/ .md-agree-icon .md-agree-icon-container .md-icon.md-icon-check {
  font-size: 0.6rem;
  color: var(--color);
  // opacity: 0.6;
}

.del {
  margin-top: 0.45rem;
  font-size: 0.36rem;
  color: #ff5a36;
  text-align: center;
  width: 10%;
}

.pro_name {
  width: 100%;
  line-height: 1.01rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.39rem;
  margin-top: 0.25rem;
  color: #666666;
}

.detali_del {
  margin-right: 1.2rem;
}

table td {
  font-size: 0.35rem;
}

.change_color {
  background-color: white;
  width: 35%;
  color: #FD5A38;
}

.fle /deep/ .md-popup-title-bar {
  border-bottom: 1px solid #F6F6F6;
}

.fle {
  margin-top: 0.8rem;
}

.search_text {
  position: relative;
}

.all {
  display: flex;
}

.baoxian_xainzhong {
  >div:first-child {
    width: 3.49rem;
    background-color: #f2f2f2;
    height: 65vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    color: #666666;
    font-size: 0.39rem;

    >div {
      width: 100%;
      padding: 0.2rem 0.3rem;
      border-bottom: 1px solid #e9e9e9;
      line-height: 1rem;
      font-size: 0.39rem;
      height: 1.27rem;
    }
  }

  >div:nth-child(2) {
    padding: 0rem 0.3rem;
    width: 65%;
    height: 100%;
    overflow: scroll;
  }

  >div:last-child {
    margin-bottom: 3rem;
  }

  height: 100%;
  display: flex;
  padding-bottom: 2.7rem;
}

.xian_message {
  margin-top: 0.6rem;
}

.popup_product /deep/ .md-icon.icon-font.lg {
  position: absolute;
  left: 1rem;
  top: 0.45rem;
}

.search_text>input {
  padding: 0.25rem 1.3rem;
  background-color: #F2F2F2;
  border-radius: 0.8rem;
  width: 75%;
  margin: 0.2rem 5%;
}

.search_text>span {
  position: absolute;
  right: 0.6rem;
  top: 0.35rem;
}

.popup_product {
  background-color: white;
  height: 100%;
}

.fle /deep/ .md-popup-title-bar .title-bar-title p.title {
  font-weight: 600;
  color: #333333;
  font-size: 0.46rem;
}

.shou_money {
  margin-left: 0.3rem;
  margin-top: 0.4rem;
  color: #666;
}

.add {
  margin-top: 0.35rem;
  text-align: center;
  border: 1px solid;
  margin-left: 5.2rem;
  width: 3.5rem;
  padding: 0.1rem;
  border-radius: 1rem;
}

.icon {
  transform: rotate(90deg);
  position: absolute;
  top: 0.17rem;
  left: 4rem;
}

/deep/ .icon1:before {
  content: '';
}

.icon1 {
  margin-left: 2.7rem;
}

.sure_info {
  position: fixed; /* margin-top: 0.5rem; */
  bottom: 0rem;
  width: 100%;
  height: 1.4rem;
  background-color: white;
  z-index: 99;
}

.sure_info_button {
  border-radius: 0.7rem;
  color: white;
}

.sure_info /deep/ .md-button.block {
  height: 1rem;
  margin-top: 0.2rem;
  background-color: #FF5A35;
}

.g_k {
  margin-top: 0.45rem;
  font-size: 0.36rem;
  color: #ff5a36;
  width: 2%;
  margin-left: 0.1rem;
}

.update_message-top {
  margin-top: 0.45rem;
  font-size: 0.36rem;
  width: 21%;
  text-align: center;
}

.popup /deep/ .coverage_info .message {
  margin-top: 1.18rem;
  margin-bottom: -0.1rem;
}

.info_name {
  font-weight: 600;
  margin-top: 0.4rem;
  margin-left: 0.3rem;
  width: 60%;
  font-size: 0.39rem;
}

.info_info {
  margin: 1.5rem 0.3rem 0.2rem 0.3rem;
  overflow: hidden;
}

/deep/ .md-popup-title-bar .title-bar-title {
  line-height: 0;
}

/deep/ .md-field-item-title {
  color: #89868a;
}

/deep/ .md-field-item.is-solid .md-field-item-title {
  color: #89868A;
}

/deep/ .md-input-item.left .md-input-item-input, .md-input-item.left .md-input-item-fake {
  border: 1px solid #cfd3dc;
  height: 0.75rem;
  width: 5.21rem;
  margin-left: 0.65rem;
  padding-left: 0.2rem;
  padding-right: .9rem;
}

.md-button.block {
  width: 95%;
  margin: 0 auto;
}

.messagelist-bomrg /deep/ .md-button.block {
  width: 2.57rem;
  border-radius: 0.15rem;
  height: 0.93rem;
  background-color: #FD5A38;
}

.update_info {
  background-color: #F4F1F4;
  padding-bottom: 0.8rem;
  padding-top: 0.4rem;

  /deep/ .md-field-item-control {
    justify-content: flex-end;
    display: flex;
  }
}

.name_sex_age {
  margin: 0 auto;
  background-color: white;
  width: 90%;
  height: 4.5rem;
  padding: 0.3rem 0.3rem;
  position: relative;

  span {
    position: absolute;
    top: 0.6rem;
    z-index: 1;
    width: 0.35rem;
    height: 0.43rem;
    right: 0.7rem;
  }
}

input::-webkit-input-placeholder {
  color: #D1D1D1;
}

.keep_button {
  background-color: #faf7f9;
  height: 1.6rem;
  padding-top: 0.2rem;
  padding: 0.3rem 0;
  z-index: 99;
}

.choose_age {
  position: fixed;
  top: 5vh;
  left: 5vh;
  right: 5vh;
  width: 80%;
  height: 90vh;
  background-color: #fff;
  margin: 0 auto;
  padding: 0.4rem 0.3rem;
  overflow: scroll;
  z-index: 10;
}

.bir {
  width: 45%;
  border: 1px solid #BBBDC2;
  padding: 0.1rem 0.2rem;
  text-align: left;
  height: 0.8rem;
}

.Age {
  width: 2.5rem;
  border: 1px solid #BBBDC2;
  padding: 0.1rem 0.2rem;
  text-align: left;
  margin-left: 0.2rem;
  color: #606060;
  height: 0.8rem;
}

.up_icon {
  width: 1.5rem;
  height: 0.5rem;
}

.icon /deep/ .md-icon.icon-font:before {
  font-size: 0.25rem;
}

.icon_right {
  margin-top: 0.3rem;
  margin-left: 0.1rem;
}

.set {
  margin-top: 0.22rem;
  font-size: 0.38rem;
}

/deep/ .md-check-base-box .md-tag {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
}

.md-check-base-box.is-checked {
  color: red;
  border-color: red;
}

/deep/ .md-radio-group {
  margin-left: 0.02rem;
  width: 100%;
}

.md-radio-group .md-check-base-box {
  width: 1.7rem;
  // height: 0.71rem;
}

.md-example-popup {
  width: 100%;
  position: relative;
  font-size: 28px;
  font-family: DINPro;
  font-weight: 500;
  box-sizing: border-box;
  text-align: center;
  background-color: #FFF;
}

.md-example-popup-right {
  height: 100%;
  width: 80vw;
  // display: flex;
  // align-items: center;
}

.mainbody {
  background-color: #F7F6FB;
  width: 100vw;
  height: 100vh;

  // overflow: hidden;
  ::-webkit-scrollbar {
    /* 隐藏滚轮 */
    display: none;
  }
}

.messagelist {
  width: 90vw;
  padding-bottom: 8vh;
  padding-top: 2vh;
  margin: 0 auto;
  background-color: #f7f6fb;
  height: 100vh;
  overflow-y: auto;
}

.dangerlistce {
  height: 100vh;
  width: 95%;
  margin: 0 auto;
}

.dangerlistce p {
  height: 100px;
  line-height: 100px;
  border-bottom: 1px solid #ccc;
}

.dangerlistce .md-check {
  margin: 20px 0;
}

.dangerlist-bom {
  width: 100%;
  height: 7%;
  z-index: 5;
}

.messagelist-bom {
  z-index: 5;
  width: 100vw;
  height: 1.5rem;
  position: fixed;
  bottom: 0;
  background: #fff;
  display: flex;
  box-shadow: 0px 0px 0.2rem #E8E7E7;
}

.messfu {
  width: 80vw;
  margin: 0 auto;
  border: 1px solid #eee;
}

.popup_title {
  position: fixed;
  width: 100%;
  z-index: 1;
}

.coverage_info /deep/ .message {
  margin: 0;
}

.coverage_info /deep/.md-popup-box {
  max-height: 80vh;
  background-color: #F2F2F2;
}

.coverage_info {
  background: #fff;
  margin-top: -30px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-radius: 5px;
}

.message {
  background: #fff;
  padding-bottom: 30px;
  border-radius: 5px;
}

.message-top {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #eee;
  align-items: center;
  padding: 0.2rem 0.3rem;
}

.wbtop {
  padding: 0.2rem 0.3rem 0.2rem 0;
  justify-content: space-between;

  p {
    width: auto !important;
  }

  .leftbox {
    display: flex;
    align-items: center;
  }
}

.redbg {
  display: inline-block;
  background-color: red;
  width: 0.13rem;
  height: 0.5rem;
}

.message-top img {
  width: 50px;
  height: 50px;
}

.message-top .md-agree {
}

.message-top p, .coverage_info p {
  font-weight: 700;
  width: 85%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 0.46rem;
  padding: 0 0.2rem;
}

// .message-top p::before, .coverage_info p::before {
// content: '';
// width: 0.35rem;
// height: 0.5rem;
// position: absolute;
// left: 2px;
// margin-top: 0.07rem;
// margin-left: -0.25rem;
// background-color: var(--color);
// }
.messagelist-bomlf, .messagelist-bomrg {
  flex: 1;
  height: 100%;
}

.messagelist-bomlf {
  margin-top: 0.2rem;
  margin-left: 0.6rem;
}

.zongbaofei {
  margin-bottom: 0.03rem;
  color: #8A847E;
}

.messagelist-bomrg {
  margin-left: 20%;
  margin-top: 0.3rem;
}

.md-example-popup-right .md-scroll-view {
  width: 100%;
  height: 93%;
  overflow-y: auto;
}

.message-bompd {
  padding: 0 40px;
}

.md-button.block {
  height: 100%;
  border-radius: 0.1rem;
  background-color: #FF5A36;
}

/deep/ .md-stepper-button:after {
  width: 35px;
  height: 3px;
}

/deep/ .md-stepper-button.md-stepper-button-add:before {
  width: 3px;
  height: 35px;
}

.md-radio-group .md-check-base-box:nth-child(2) {
  width: 1.7rem;
  // height: 0.71rem;
  margin-left: 0.2rem;
}

// .message-top .md-radio-group .md-check-base-box:nth-child(2) {
// margin-right: 0.25rem;
// }
/deep/ .md-toast .md-toast-text {
  background: #000;
}

/deep/ .md-toast-text {
  word-wrap: break-word !important;
  overflow: auto !important;
}

/deep/ .md-selector .md-radio-item.is-selected .md-cell-item-title {
  color: #111a34;
}

.rightje /deep/ .md-field-item-control {
  text-align: right;
  flex: 0;
}

/deep/.md-field-item-title {
  font-size: 0.38rem;
}

/deep/.md-field-item-control {
  font-size: 0.4rem;
}

.ag {
  display: flex;
  justify-content: flex-end;
}

/deep/.md-input-item-input {
  font-size: 0.4rem;
}

/deep/ .md-check-label {
  margin-left: 0.15rem;
  font-size: inherit;
  text-align: left;
}

.thesame {
  position: absolute;
  width: 1.7rem;
  line-height: 0.6rem;
  text-align: center;
  font-size: 0.3rem;
  right: 0.2rem;
  top: 0.15rem;
  border: 0.01rem solid;
}

.searchline {
  border-radius: 0.5rem;
  background: #f2f2f2;
  padding: 0 0.5rem;
  width: 80%;
}

.inputss {
  line-height: 1rem;
  height: 1rem;
  background-color: #f2f2f2;
  margin-left: 0.3rem;
  margin-right: 0.2rem;
  width: 75%;
  font-size: 0.35rem;
  font-family: PingFang-SC-Medium;
  font-weight: 500;
}

.searchTxt {
  color: #989898;
  margin-left: 0.3rem;
}

.searchbox {
  display: flex;
  align-items: center;
  padding: 0.3rem 0 0.3rem 0.7rem;
  background-color: #fff;
  margin-bottom: 0.3rem;
  border-bottom: 0.01rem solid #F2F2F2;
}

.popupmd /deep/.md-popup-box {
  background-color: #fff;
}

.mesosphere {
  max-height: 20vh;
  padding: 0.3rem 0.5rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.3rem;
}

.osphsunce {
  display: flex;
  align-items: center;
  margin: 0 0.2rem 0.2rem;
}

.substance {
  display: flex;
  align-items: center;
  padding: 0.2rem;
  margin-right: 0.2rem;
  background-color: #F2F2F2;
  border-radius: 0.2rem;

  span {
    padding: 0 0.2rem;
    font-size: 0.35rem;
  }
}

.lowerstratum {
  height: 40vh;
  // display: flex;
  padding: 0rem 0.4rem;
}

// .stratumleft {
// width: 35%;
// display: flex;
// flex-direction: column;
// padding: 0.2rem;
// border-right: 0.01rem solid #f0f0f0;
// overflow: auto;

// span {
// padding: 0.2rem;
// border-bottom: 0.01rem solid #e0e0e0;
// }
// }
.stratumright {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.2rem;
  overflow: auto;

  /deep/ .md-check-label {
    margin-left: 0.15rem;
    font-size: inherit;
    text-align: left;
    overflow: hidden; /* 自动隐藏文字 */
    text-overflow: ellipsis; /* 文字隐藏后添加省略号 */
    white-space: nowrap; /* 强制不换行 */
  }

  /deep/.md-check {
    display: flex;
    align-items: center;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
}

.popup {
  info_info:first-child {
    margin: 1.5rem 0.3rem 2rem 0.3rem;
    overflow: hidden;
  }

  info_info:last-child {
    margin: 1.5rem 0.3rem 2rem 0.3rem;
    overflow: hidden;
  }
}

.img_td {
  display: flex;
  justify-content: space-between;

  img {
    width: 0.6rem;
    align-self: center;
  }
}

.tsinfo {
  background-color: rgba(65, 72, 93, 0.77);
  width: 54%;
  position: fixed;
  top: 50%;
  left: 23%;
  padding: 0.2rem 0.3rem;
  border-radius: 0.04rem;
  color: white;
}
</style>

